/* 
    * ======================== *   
    * Define Vars *
    * ======================== *
*/
// const currentWindowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
var getScreenWidth = () => {
    return currentWindowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
}
/* 
    * end/ Define Vars *
*/




/* 
    * ======================== *
    * Import _modernizr.js *
    * ======================== *
*/
require('./is-img-webp.js');
/* 
    * end/ Import modernizr.js  *
*/




/* 
    * ======================== *
    * Imports Yarn packages using CommonJS modules import method  *
    * ======================== *
*/
const $ = require('jquery');

// jQuery usefull plugins
const backstretch = require('jquery-backstretch');
const hammerjs = require('hammerjs');
const Flickity = require("flickity");
const L = require('leaflet');
require("leaflet-providers");
const polyfillObserver = require('intersection-observer');
const lozad = require('lozad');
/*
    * end/ Imports Yarn packages *
*/




/*
    * ======================== *
    * Launch native javascript plugin *
    * ======================== *
*/
// Wowjs
const WOW = require('wowjs');
window.wow = new WOW.WOW({
    live: false
});
window.wow.init();

// Fastclick
const FastClick = require('fastclick');
FastClick.attach(document.body);

// LazyLoad on images with Lozad.js
const imgObserver = lozad('[data-lazy]', {
    rootMargin: '250px 0px'
});
imgObserver.observe();
/*
    * end/ Launch native javascript plugin *
*/




/*
    * ======================== *
    * jQuery Plugins
    * ======================== *
*/
$(function () {
    /*
    -- @ LINKS @ --
    */
    // External links
    $(document).on('click', 'a[href$=".pdf"], a.external-link', function (e) {
        e.preventDefault()
        window.open($(this).attr("href"))
    })
    // Disabled links
    $(document).on('click', 'a.noLink, a[href="GOTOLINK"]', function (e) {
        e.preventDefault()
    })
    // Backtotop links
    $(document).on('click', 'a.backtotop', function (e) {
        e.preventDefault()
        $('body,html').animate({scrollTop: 0}, 250, 'swing');
    })




    /*
        -- @ MAPS FOOTER @ --
        * Leaflet maps
    */
    // Launch OpenStreetMap with lazy load
    const mapObserver = lozad('#leaflet-maps', {
        rootMargin: '250px 0px',
        loaded: function(el) {
    
            // Set Map
            var mymap = L.map('leaflet-maps', {
                center: [45.682909, 5.936201],
                zoom: 14,
                zoomControl: false
            });

            // Apply Layer
            L.tileLayer.provider('OpenStreetMap.France').addTo(mymap);

            // Disabled zoom with mousewheel
            mymap.scrollWheelZoom.disable();

            // Set custom Marker
            var customMarker = L.icon({
                iconUrl: '/images/marker-maps.png',
                iconSize: [51, 68], // size of the icon
                iconAnchor: [25, 68], // point of the icon which will correspond to marker's location
                popupAnchor: [0, -70]
            });

            // Apply marker and set pop-up
            L.marker(
                [
                    45.682909, 5.936201
                ],
                {
                    icon: customMarker
                }
            )
            .addTo(mymap)
            .bindPopup("<p style='text-align:center;'><strong>Cabinet de Chirurgie Dentaire du Revard</strong><br />4 Passage des Sources<br />73100 MOUXY<br /><br /> Tél. 04 79 61 96 36</p>")
            .openPopup();

            // Set Zoom buttons to bottom right
            L.control.zoom({
                position:'bottomright'
            }).addTo(mymap);

            // Id browser is bullshit
            if (L.Browser.ielt9) {
                alert('Votre navigateur internet est obsolète. Merci de mettre à jour votre navigateur internet en utilisant Google Chrome ou Mozilla Firefox par exemple.');
            }
        }
    });
    mapObserver.observe();
    


    /*
        -- @ BACKSTRETCH @ --
        * Attach responsive background-images to elements
    */
    // Launch Backstretch with lazy load
    const backstretchObserver = lozad('.backstretch', {
        rootMargin: '50px 0px',
        loaded: function(el) {
            var imgName = $(el).attr('data-img');

            if (typeof imgName !== 'undefined' && imgName.length) {
                
                // If brower supports WebP img, so change image extension 8) \m/
                if(Modernizr.webp) {
                    imgName = imgName.substr(0, imgName.lastIndexOf(".")) + ".webp";
                }

                $(el).backstretch(imgName);
            }
        }
    });
    backstretchObserver.observe();
    



    /*
        -- @ MAIN MENU PHONE @ --
        * Show the hamburger button for main menu on phones
    */
    var _closeMenus = function () {
        $('html, body').removeClass('opened-menu')
        // Remove "shown" class to all menus
        $('#main-header nav').removeClass('shown')
        // Remove "is-active" class to all hamburger buttons
        $('.js-open-menu button').removeClass('is-active')
    }
    var _openMenu = function (menu, button) {
        $('html, body').addClass('opened-menu')
        menu.addClass('shown')
        button.addClass('is-active')
    }
    // Click on Hamburger
    $(document).on('click', '.js-open-menu button', function (e) {
        e.preventDefault()

        var $menu = $(this).parent().next()

        if (!$menu.is('nav')) {
            alert('<nav> element must be placed just after the hamburger (msg from app.js)')
            return;
        }

        if ($(this).hasClass('is-active')) {
            _closeMenus();
        } else {
            _openMenu($menu, $(this));
        }
    });

    // HammerJS
    if ($('#js-main-nav').length) {
        // Swipe left to close menus
        var navBottomHeader = document.getElementById('js-main-nav');
        var hammerBottomMenu = new Hammer(navBottomHeader);

        hammerBottomMenu.on('swipeleft', function (ev) {
            _closeMenus();
        });
    }




    /*
     -- @ FLICKITY PLUGIN @ --
        * Used in:
        	- xxx
    */
    if ($('.main-carousel').length) {

        if ($('.main-carousel').length) {

            var elem = document.querySelector('.main-carousel');

            var flkty = new Flickity(elem, {
                wrapAround: true, // Infinite horizontal scroll ?
                lazyLoad: true,

                // Player
                autoPlay: 9000, // Move each x miliseconds
                pauseAutoPlayOnHover: true, // Pause on hover ?

                // Slider animations
                selectedAttraction: 0.08, // Animation speed
                friction: 0.7,

                // Arrows
                prevNextButtons: false
            });

            // When we click on arrow, it make the slider in pause. So, launch player after clicking
            flkty.on('select.flickity', function () {
                flkty.flickity('playPlayer');
            });
            // When slider is loaded, hide loader and show slider
            $('.wrap-loader').fadeOut('fast', function () {
                $(this).remove();
                $(elem).add('.wrap-titles').addClass('shown');
            });
        }
    }
    



    /*
        -- @ Modals @ --
    */
    /* Open modal */
    $(document).on('click', '[data-modal]',function(e){
        e.preventDefault();

        var 
            modalId = $(this).data('target') || $(this).data('modal'),
            $modal = $('#'+modalId);

        if($modal.length) {

            $('html,body').addClass('opened-modal');
            $modal.addClass('active');

            // Callback
            $modal.trigger('cc.modal.open');
        } else {
            alert('ChuckCSS error : modal with attribute id="'+modalId+'" does not exist!');
        }
    });

    /* Close modal */
    $(document).on('click', '.modal:not([data-disabled-overlay]) .modal-overlay, .modal *[data-close-modal]', function (e) {
        e.preventDefault();

        var $modal = $(this).parents('.modal');


        if ($modal.hasClass('active'))
            $modal.removeClass('active');

        if (!$('.modal.active').length)
            $('html,body').removeClass('opened-modal');

        // Callback
        $modal.trigger('cc.modal.close');
    });

    /* Modal callback */
    $('#modal-id')
        .on('cc.modal.open', function () {
            _closeMenus();
        })
        .on('cc.modal.close', function () {
            // Do something...
        });




    /*
        -- Bindable links --
        Used for : display phone, address, openings hours on phone when push icons are clicked
    */
    $(document).on('click','[data-click]', function(e) {
        var 
            _rel = $(this).data('click'),
            _pushes = $('.pushes-phone .push');

        if(_rel != '') {
            _pushes.hide();
            $(_rel).show();
        }

    });
});
/*
    * end/ jQuery Plugins
*/